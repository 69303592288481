// src/components/TutorApp.js
import React, { useState } from 'react';
import StudentName from './StudentName';
import TutorCalendar from './TutorCalendar';

const TutorApp = () => {
    const [studentName, setStudentName] = useState(null);

    if (!studentName) {
        return <StudentName onNameSet={setStudentName} />;
    }

    return <TutorCalendar studentName={studentName} />;
}

export default TutorApp;
