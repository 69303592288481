import React from 'react';
import './App.css';
import { Container, CssBaseline, AppBar, Toolbar, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TutorApp from "./components/TutorApp";

const theme = createTheme();

function App() {
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <CssBaseline />
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6">
                            Tutor Availability Calendar
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container style={{ marginTop: '20px' }}>
                    <TutorApp />
                </Container>
            </div>
        </ThemeProvider>
    );
}

export default App;
