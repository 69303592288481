// src/components/StudentName.js
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Button, TextField } from '@mui/material';

const StudentName = ({ onNameSet }) => {
    const [name, setName] = useState('');

    useEffect(() => {
        const storedName = Cookies.get('studentName');
        if (storedName) {
            setName(storedName);
            onNameSet(storedName);
        }
    }, [onNameSet]);

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleSubmit = () => {
        Cookies.set('studentName', name);
        onNameSet(name);
    }

    return (
        <div style={{ padding: '20px' }}>
            <TextField
                label="Enter your name"
                value={name}
                onChange={handleNameChange}
                fullWidth
            />
            <Button
                variant="contained"
                color="primary"
                style={{ marginTop: '20px' }}
                onClick={handleSubmit}
            >
                Submit
            </Button>
        </div>
    );
}

export default StudentName;
