import React, {useEffect, useState} from 'react';
import {Grid, Button, Typography, Alert} from '@mui/material';
import axios from 'axios';

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
const hours = ['10am-11am', '11am-12pm', '12pm-1pm', '1pm-2pm', '2pm-3pm', '3pm-4pm', '4pm-5pm', '5pm-6pm', '6pm-7pm', '7pm-8pm', '8pm-9pm', '9pm-10pm'];
const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://127.0.0.1:8000';

const TutorCalendar = ({studentName}) => {

    const [studentSlots, setStudentSlots] = useState([]);
    const [allReservedSlots, setAllReservedSlots] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');


    const fetchReservedSlots = async () => {
        try {
            const response = await axios.get(`${backendUrl}/api/getReservedSlots`, {
                params: {student_name: studentName}
            });
            setStudentSlots(response.data.studentReservedSlots || []);
            setAllReservedSlots(response.data.allReservedSlots || []);
        } catch (error) {
            console.error("Error fetching reserved slots:", error);
            setAlertMessage("Error fetching reserved slots. Please try again later.");
            setShowAlert(true);
        }
    };

    useEffect(() => {
        fetchReservedSlots();
    }, []);

    const isResolvedByMe = (day, hour) => {
        return studentSlots.some(slot => slot.day === day && slot.hour === hour);
    };

    const isSlotReservedByOthers = (day, hour) => {
        return allReservedSlots.some(slot => slot.day === day && slot.hour === hour) && !isResolvedByMe(day, hour);
    };

    const toggleSlot = async (day, hour) => {
        const isReserved = isResolvedByMe(day, hour);

        try {
            const payload = {
                student_name: studentName,
                day: day,
                hour: hour,
                action: isReserved ? 'remove' : 'add'
            };
            await axios.post(`${backendUrl}/api/reserveSlot/`, payload);
            fetchReservedSlots();

        } catch (error) {
            console.error("Error updating reserved slot:", error.response ? error.response.data : error.message);
            setStudentSlots(prevState => [...prevState]);
            setAlertMessage(error.response && error.response.data.detail ? error.response.data.detail : error.message);
            setShowAlert(true);
        }
    };

    return (
        <Grid container spacing={2} style={{display: 'flex'}}>
            {showAlert && typeof alertMessage === 'string' && (
                <Grid item xs={12} style={{marginBottom: '20px'}}>
                    <Alert severity="error" onClose={() => setShowAlert(false)}>
                        {alertMessage}
                    </Alert>
                </Grid>
            )}
            {days.map((day) => (
                <Grid key={day} item xs={12} style={{padding: '10px', border: '1px solid gray', flex: 1}}>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        <Typography variant="h6" align="center">{day}</Typography>
                        {hours.map((hour) => (
                            <Button
                                key={hour}
                                variant="contained"
                                fullWidth
                                style={{
                                    marginBottom: '8px',
                                    marginTop: '8px',
                                    backgroundColor: isResolvedByMe(day, hour)
                                        ? 'green'
                                        : (isSlotReservedByOthers(day, hour) ? 'gray' : 'white'),
                                    color: (isResolvedByMe(day, hour) || isSlotReservedByOthers(day, hour)) ? 'white' : 'black'
                                }}
                                onClick={() => !isSlotReservedByOthers(day, hour) && toggleSlot(day, hour)}
                                disabled={isSlotReservedByOthers(day, hour)}
                            >
                                {hour}
                            </Button>
                        ))}
                    </div>
                </Grid>
            ))}
        </Grid>
    );
}

export default TutorCalendar;
